@import url("https://unpkg.com/@tailwindcss/typography@0.2.x/dist/typography.min.css");  
@import url("https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css");
@import url("https://fonts.googleapis.com/css?family=Overpass");

.cursive{
  font-family: "Overpass";
  font-weight: 700;
}

.prose img {
  margin-left:auto;
  margin-right:auto;
  border-radius: 5px;
}

.home-name{
  font-size: 150px;
}